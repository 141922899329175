export const specialRouteTitle = {
  '/': 1
}

export const approvalStatusObj = {
  // 详情 审批状态
  1: {
    text: '待审批',
    color: 'gary'
  },
  2: {
    text: '审批中',
    color: '#FF9300'
  },
  3: {
    text: '同意',
    color: '#52c41A'
  },
  4: {
    text: '拒绝',
    color: '#FF4D4F'
  }
}

export const archiveStatusObj = {
  // 归档状态
  1: {
    name: '待审批',
    color: '#ccc'
  },
  2: {
    name: '审批中',
    color: '#FF9300'
  },
  3: {
    name: '已同意',
    color: '#52C41A'
  },
  4: {
    name: '已拒绝',
    color: '#F5222D'
  },
  5: {
    name: '已撤销',
    color: '#C3161C'
  }
}

export const examineStatusObj = {
  // 列表 审批状态
  0: {
    name: '审批中',
    color: '#FAAD14'
  },
  1: {
    name: '已同意',
    color: '#52C41A'
  },
  2: {
    name: '已拒绝',
    color: '#F5222D'
  },
  3: {
    name: '已撤销',
    color: '#F5222D'
  },
  4: {
    name: '已结束',
    color: '#969799'
  }
  // 3: {
  //   name: '归档审批中',
  //   color: '#1890FF'
  // },
  // 4: {
  //   name: '归档审批通过',
  //   color: '#52C41A'
  // },
  // 5: {
  //   name: '归档审批未通过',
  //   color: '#FF4D4F'
  // }
}
export const examineStatusObjs = {
  // 列表 审批状态
  0: {
    name: '审批中',
    color: '#FAAD14'
  },
  1: {
    name: '已同意',
    color: '#52C41A'
  },
  2: {
    name: '已拒绝',
    color: '#F5222D'
  }
}

export const sealStatusObj = {
  //用印状态 不可用0，待用印1，用印中2，用印完成3
  0: {
    name: '不可用',
    color: '#F5222D'
  },
  1: {
    name: '待用印',
    color: '#FAAD14'
  },
  2: {
    name: '用印中',
    color: '#1890FF'
  },
  3: {
    name: '已完成',
    color: '#52C41A'
  },
  4: {
    name: '已结束',
    color: '#969799'
  },
  5: {
    name: '已归档',
    color: '#52C41A'
  },
  6: {
    name: '归档中',
    color: '#A518FF'
  }
}

export const warningStatusObj = {
  3: { name: '异地盖印预警' },
  4: { name: '印章异常预警' },
  5: { name: '空白页盖印预警' },
  6: { name: '低电量预警' },
  9: { name: '超出围栏预警' },
  11: { name: '超时按压预警' },
  20: { name: '即将超时用印预警' },
  21: { name: '已超时用印预警' },
  19: { name: '用印未补拍预警' },
  29: { name: '物品拿错预警' },
  30: { name: '物品多拿预警' },
  31: { name: '归还错误预警' },
  32: { name: '柜门未关闭预警' },
  33: { name: '取出成功提醒' },
  34: { name: '归还成功提醒' }
}

export const sealTypeStatusObj = {
  1: { name: '蘸墨印章' },
  2: { name: '非蘸墨印章' }
}

export const paginationParams = {
  pageSize: 10,
  total: 0,
  current: 1,
  index: 0,
  'show-size-changer': true,
  'show-quick-jumper': true
}

export const fileTypeObj = {
  pdf: 'pdf.png',
  doc: 'word.png',
  docx: 'word.png',
  xls: 'excel.png',
  xlsx: 'excel.png',
  txt: 'txt.png'
}

// 用印状态 不可用0，待用印1，用印中2，用印完成3
export const sealStatus = {
  0: {
    status: '不可用',
    color: '#F5222D'
  },
  1: {
    status: '待用印',
    color: '#FAAD14'
  },
  2: {
    status: '用印中',
    color: '#1890FF'
  },
  3: {
    status: '已完成',
    color: '#52C41A'
  },
  4: {
    status: '已结束',
    color: '#969799'
  },
  5: {
    status: '已归档',
    color: '#52C41A'
  },
  6: {
    status: '归档中',
    color: '#A518FF'
  }
}

// 电子签章 - 文件管理 - 签署状态
export const signStatus = {
  1: {
    status: '待我签署',
    color: '#FAAD14'
  },
  2: {
    status: '待他人签署',
    color: '#1890FF'
  },
  3: {
    status: '已完成',
    color: '#52C41A'
  },
  4: {
    status: '已过期',
    color: '#969799'
  }
}

//印章类型
export const sealType = {
  1: { name: '实体章' },
  2: { name: '电子章' }
}

//搜索类型
export const searchType = {
  1: { name: '流程主题' },
  2: { name: '申请人' },
  3: { name: '印章名称' },
  4: { name: '盖印人' }
}

//审批状态
export const approvalStatusList = [
  {
    id: 1,
    name: '已同意'
  },
  {
    id: 2,
    name: '已拒绝'
  }
]

// 流程名称 业务类型
export const processTypeObj = {
  1: { name: '普通申请' },
  2: { name: '自助申请' }
}

export const processType = [
  { name: '用印流程' },
  { name: '归档流程' },
  { name: '刻章流程' },
  { name: '变更流程' },
  { name: '装卸流程' },
  { name: '移交流程' },
  { name: '销毁流程' }
]
